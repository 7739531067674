.product-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-button-group {
    display: flex;
    justify-content: end;

}

.product-button {
    margin: 10px !important;
    color: white !important;
}

/* add diolog box css  */

.add-dialog-box {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.158);
    align-items: center;
    justify-content: center;
}

.add-dialog-inner {
    position: relative;
    border-radius: 10px;
    background-color: white;
    max-width: 600px;
    width: 100%;
}

.add-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.add-dialog-inputs {
    display: flex;
    justify-content: space-between;
    margin: 15px;

}

.add-inputs {
    width: 48%;
}

.add-save-btn {
    color: white;
}

/* Table css */

.product-table {
    width: 100%;
    overflow-x: auto;
}


#product-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;    
}

#product-table tr{

   
}

.product-searchbar th input{
    padding: 3px;
    border-radius: 5px;
    border: 2px solid gray;
}

.product-searchbar {
   margin: 5px; 
}

.product-table tr th{
    padding-right: 5px;
}

.product-table tr td{
    padding-right: 5px;
}









