.admin-container {
	display: flex;
	align-items: stretch;
}

.admin-container .outlet-conainer {
	margin: 0 auto;
	padding: 32px;

	min-height: 100vh;
	max-width: 1300px;
	width: 100%;
	overflow: hidden;
}

@media (max-width: 920px) {
	.admin-container .outlet-conainer {
		padding: 16px;
	}

	.admin-container .outlet-sidebar {
		width: 60px;
		max-width: 60px;	
	}
}