
.gallery-img  {
    position: relative;
    width: 50%;
    max-width: 300px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    opacity: 1;
  }
  
  #img-btn {
    position: absolute; 
    display: none;

  }
  .gallery-img:hover #img-btn { 
    display: block;
    top: 0; 
    right: 0%;
    background-color: rgba(0, 0, 0, 0.377);
    text-align: center;
    opacity: 1;
  }
  
  .gallery-img:hover { 
    opacity: 0.9;
    
  }
  
  