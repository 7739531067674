li {
	list-style: none;
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.Select_icon{
	padding: 0px  !important;

}

.admin-container {
	background-color: #f5f6fa;
}

.inqury-view{
	color: rgb(94, 197, 73) !important;
}