/* .admin-container .outlet-sidebar {
	transition: all 0.5s;
} */

.admin-container .outlet-sidebar.open {
	min-width: 250px;
}

.admin-container .outlet-sidebar.close {
	width: 60px;
	min-width: 50px;
	overflow: hidden;
}

.admin-container .outlet-sidebar.close .sidebar-container .sidebar-header {
	padding: 8px 5px;
	margin-bottom: 10px;
	/* background-color: transparent; */

	flex-direction: column;
	/* gap: 10px; */
}

.admin-container .outlet-sidebar.close .sidebar-container .sidebar-body {
	padding: 0px;
}

.admin-container .outlet-sidebar.close .sidebar-container .sidebar-body .sidebar-menu-container {
	gap: 10px;
}

.admin-container .outlet-sidebar.close .sidebar-container .sidebar-menu-container .sidebar-menu-link {
	border-radius: 0;
	background-color: transparent;
	transition: all 0.3s;
}

.admin-container .outlet-sidebar.close .sidebar-container .sidebar-menu-container .sidebar-menu-link.active {
	background-color: var(--secondary-main);
}

.admin-container .outlet-sidebar.close .sidebar-container .sidebar-menu-container .sidebar-menu-link:hover {
	background-color: var(--secondary-dark);
}

.sidebar-container {
	height: 100%;
	width: 100%;

	border-right: 1px solid #eaf0f2;
	background-color: var(--white);
}

.sidebar-container .sidebar-header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 20px;
	/* background-color: var(--secondary-dark); */
	
	height: 81px;
	overflow: hidden;
}

.sidebar-container .sidebar-body {
	padding: 20px;

	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: center;
}

.sidebar-container .sidebar-body .sidebar-menu-container {
	height: 100%;

	display: flex;
	flex-direction: column;
	gap: 20px;
}

.sidebar-container .sidebar-menu-container .sidebar-menu-link {
	width: 100%;
	height: 45px;
	
	padding: 0px 12px;
	
	border-radius: 8px;
	text-decoration: none;
	
	display: flex;
	align-items: center;
}

.sidebar-container .sidebar-menu-container .sidebar-menu-link.active {
	background-color: var(--primary-dark);
	
}

.sidebar-container .sidebar-menu-container .sidebar-menu-link li {
	display: flex;
	align-items: center;
}

.sidebar-container .sidebar-menu-container .sidebar-menu-link li .sidebar-menu-icon {
	height: 25px;
	margin-right: 10px;
}

.sidebar-container .sidebar-menu-container .sidebar-menu-link li .sidebar-menu-icon svg {
	font-size: 28px;
	color: var(--black);
	fill: var(--black);
}

.sidebar-container .sidebar-menu-container .sidebar-menu-link li p {
	/* color: white; */
	text-decoration: none;
	color: var(--black);
}

.sidebar-container .sidebar-menu-container .sidebar-menu-link.active li p {
	color:var(--white) ;
}

.sidebar-container .sidebar-menu-container .sidebar-menu-link.active li .sidebar-menu-icon svg {
	
	color: var(--white);
	fill: var(--white);
}
.sidebar-container .sidebar-menu-container .sidebar-menu-link.active { 
	
	background-color: var(--primary-dark)!important;
}